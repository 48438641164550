import { useState, useEffect } from "react";
import axios from "axios";
import Notification from "../components/Notification";

export default function ContactPage() {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [notification, setNotification] = useState({
    message: "",
    isError: false,
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { firstName, lastName, email, message } = form;
    if (!firstName || !lastName || !message) {
      setNotification({
        message: "Please fill in all the required fields",
        isError: true,
      });
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email || !emailRegex.test(email)) {
      setNotification({
        message: "Please enter a valid email address",
        isError: true,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const serverURL = process.env.REACT_APP_serverURL;

    const email = form.email;
    const subject = `Contact from ${form.firstName} ${form.lastName}`;
    const message = form.message;

    try {
      await axios.post(`${serverURL}/send-email`, { email, subject, message });
      setNotification({
        message: "Email successfully sent",
        isError: false,
      });
    } catch (error) {
      console.error("Error sending email:", error);
      setNotification({
        message: "Error sending email",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (notification.message) {
      const timer = setTimeout(() => {
        setNotification({ message: "", isError: false });
      }, 5000); // Clear the notification after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <div className="">
      {notification.message && (
        <Notification
          message={notification.message}
          isError={notification.isError}
        />
      )}
      <div
        className="absolute inset-x-0-z-10 transform-gpu overflow-hidden blur-3xl "
        aria-hidden="true"
      ></div>
      <div className="mx-auto max-w-2xl text-left">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Contact Us
        </h2>
        <p className="mt-2 text-lg text-gray-600">
          We welcome your feedback! Please feel free to reach out to us
          regarding any suggestions, bug reports, or advertising inquiries you
          may have. We look forward to hearing from you and will do our best to
          respond in a timely manner.
        </p>
      </div>
      <form
        action="#"
        method="POST"
        className="mx-auto mt-8 max-w-2xl sm:mt-10"
      >
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label
              htmlFor="first-name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              First name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="firstName"
                id="first-name"
                autoComplete="given-name"
                className="block w-full  border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="last-name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Last name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="lastName"
                id="last-name"
                autoComplete="family-name"
                className="block w-full  border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                className="block w-full  border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Message
            </label>
            <div className="mt-2.5">
              <textarea
                name="message"
                id="message"
                rows={4}
                className="block w-full  border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"
                defaultValue={""}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            onClick={handleSubmit}
            className="block w-full  bg-main px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-mainLight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
}
