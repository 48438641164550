import { useState } from "react";

const Tooltip = ({ desc }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="relative">
      <button
        className="w-5 h-5 text-sm text-black rounded-full bg-gray-300 hover:bg-gray-400 hover:text-white hover:shadow-md items-center justify-center cursor-default"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        ?
      </button>
      <div
        className="absolute right-0 w-48 p-2 mr-2 mt-1 text-xs text-white bg-gray-700 rounded-sm shadow-md opacity-0 transition-opacity duration-500 text-left"
        style={{
          opacity: isHovered ? 0.9 : 0,
        }}
      >
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default Tooltip;
