import React from "react";
import { useParams } from "react-router-dom";
import useChartData from "../hooks/useChartData";
import { fetchCoinAdoptionData } from "../api/api";
import OutputCard from "../components/OutputCard";

const ChartPage = () => {
  // TODO coinadoption data from app state
  const { slug } = useParams();
  const coinAdoptionData = useChartData(fetchCoinAdoptionData);

  if (coinAdoptionData.loading) {
    // Your loading state here
  }

  if (coinAdoptionData.error) {
    // Your error state here
  }

  const chartData = coinAdoptionData.data
    ? Object.values(coinAdoptionData.data).find(
        (chart) => chart.chartInfo.url === slug
      )
    : null;

  console.log(chartData);

  return chartData ? (
    <div>
      <OutputCard output={chartData} />
    </div>
  ) : (
    <div>Chart not found</div>
  );
};

export default ChartPage;
