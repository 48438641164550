import React from "react";
import Badge from "./Badge";
import Tooltip from "./Tooltip";
import LineChart from "../charts/LineChart";

const OutputCard = ({ output }) => {
  const { chartData, chartInfo } = output;
  const { title, badgeLabels, desc, source } = chartInfo;

  return (
    <div>
      <div className="bg-gray-100 px-4 py-2 shadow-md">
        <div className="flex items-center justify-between mb-2 ">
          <div className="flex items-center ">
            <h2 className="text-base   font-semibold ">{title}</h2>
          </div>

          <Tooltip desc={desc} />
        </div>

        <LineChart chartData={chartData} />

        <div className="flex items-center justify-between mt-3 text-xs text-gray-400 italic">
          <h3>Source: {source}</h3>
        </div>
      </div>
    </div>
  );
};

export default OutputCard;
