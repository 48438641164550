// App.js
import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import ChartPage from "./pages/ChartPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { fetchCoinAdoptionData } from "./api/api";
import GoogleAnalytics from "./GoogleAnalytics";

const App = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const data = await fetchCoinAdoptionData();
        setChartData(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchChartData();
  }, []);

  return (
    <BrowserRouter>
      <GoogleAnalytics />
      <div className="min-h-screen flex flex-col">
        <Header chartData={chartData} />

        <div className="flex-grow">
          <main className="p-4 mx-auto max-w-7xl">
            <Routes>
              <Route
                path="/"
                element={<HomePage coinAdoptionData={chartData} />}
              />
              <Route path="/contact" element={<ContactPage />} />
              <Route
                path="/chart/:slug"
                element={<ChartPage coinAdoptionData={chartData} />}
              />
              <Route
                path="*"
                element={<HomePage coinAdoptionData={chartData} />}
              />
            </Routes>
          </main>
        </div>

        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
