import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import "chartjs-plugin-zoom";
import "chartjs-adapter-moment"; // Add this line

const LineChart = ({ chartData, showLegend = true }) => {
  const chartRef = useRef();
  const chartInstanceRef = useRef();
  const { data, xAxisValue, yAxisLabel, type } = chartData;

  const colorPalette = ["#03C9D7", "#004346", "#679436", "#A5BE00"];

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      // console.log(
      //   "Data subset:",
      //   data.map((item) => ({
      //     ...item,
      //     values: item.values.filter((_, idx) => idx % 10 === 0),
      //   }))
      // );

      // data.forEach((item, i) => {
      //   item.values.forEach((value, j) => {
      //     if (value === null || value === undefined) {
      //       console.log(`Dataset ${i}, Index ${j}: Missing or undefined value`);
      //     }
      //   });
      // });

      xAxisValue.reduce((prev, curr, idx) => {
        if (prev && curr < prev) {
          console.log(`Sorting issue at index ${idx}: ${prev} > ${curr}`);
        }
        return curr;
      }, null);

      chartInstanceRef.current = new Chart(chartRef.current, {
        type,
        data: {
          labels: xAxisValue,
          datasets: data.map((item, idx) => ({
            label: item.legend,
            data: item.values,
            backgroundColor: colorPalette[idx],
            borderColor: colorPalette[idx],
            borderWidth: 1,
            pointRadius: 1, // Set pointRadius to 0
            pointHitRadius: 0, // Set pointHitRadius to 0
            pointHoverRadius: 0, // Set pointHoverRadius to 0
            tension: 0.6, // Adjust the tension for line smoothness
          })),
        },
        options: {
          interaction: {
            mode: null, // Disable interactions
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "year",
                displayFormats: {
                  year: "YYYY",
                },
              },
              grid: {
                display: false, // Remove vertical gridlines
              },
              ticks: {
                maxRotation: 90,
                minRotation: 90,
              },
              // maxTicks: 5, // set the maximum number of ticks to display on the x-axis to 5
              // stepSize: 2, // set the step size between ticks to 2
            },
            y: {
              title: {
                display: false,
                text: yAxisLabel,
              },
              grid: {
                display: false, // Remove horizontal gridlines
              },
              beginAtZero: true,
              ticks: {
                // Use a callback function to format the tick values
                callback: function (value, index, values) {
                  return value.toFixed(1); // Display one decimal place
                },
              },
            },
          },
          plugins: {
            legend: {
              position: "bottom", // Set the legend position to bottom
              display: showLegend,
            },
            zoom: {
              pan: {
                enabled: true,
                mode: "x",
              },
              zoom: {
                wheel: {
                  enabled: true, // Enable zooming with the mouse wheel
                },
                mode: "x",
                enabled: true,
              },
            },
          },
        },
      });
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [chartRef, data]);

  return <canvas ref={chartRef}></canvas>;
};

export default LineChart;
