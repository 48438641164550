import OutputCard from "../components/OutputCard";
import { FadeLoader } from "react-spinners";
import { Link } from "react-router-dom";

const HomePage = ({ coinAdoptionData }) => {
  const chartsData = coinAdoptionData
    ? Object.keys(coinAdoptionData).map((key) => coinAdoptionData[key])
    : [];

  if (coinAdoptionData.loading) {
    return (
      <div
        className="flex items-center justify-center"
        style={{ minHeight: "200px" }}
      >
        <FadeLoader color="#03C9D7" />
      </div>
    );
  }

  if (coinAdoptionData.error) {
    return (
      <div
        className="flex items-center justify-center text-red-500"
        style={{ minHeight: "200px" }}
      >
        Error: {coinAdoptionData.error.message}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {chartsData.map((chart, index) => {
        return (
          <Link to={`/chart/${chart.chartInfo.url}`} key={index}>
            <OutputCard output={chart} index={index} />
          </Link>
        );
      })}
    </div>
  );
};

export default HomePage;
