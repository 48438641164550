import axios from "axios";
const serverURL = process.env.REACT_APP_serverURL;

export const fetchCoinAdoptionData = async () => {
  try {
    const response = await axios.get(`${serverURL}/api/v1/coin-adoption-data`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
