import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "./Logo";
import { Link } from "react-router-dom";

export default function Header({ chartData }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const name = [];
  const url = [];

  for (const key in chartData) {
    if (
      chartData.hasOwnProperty(key) &&
      chartData[key].hasOwnProperty("chartInfo")
    ) {
      name.push(chartData[key].chartInfo.title.replace(/\s*\(.*?\)/g, ""));

      url.push(`chart/${chartData[key].chartInfo.url}`);
    }
  }

  const navigation = name.map((name, index) => {
    return { name: name, href: url[index] };
  });

  return (
    <header className="bg-mainDark text-white sticky top-0">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8  "
        aria-label="Global"
      >
        <div className="flex items-center gap-x-12">
          <Logo />
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                to={item.href}
                className="text-sm font-semibold leading-6 hover:text-main "
                key={item.name}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center p-2.5"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />

        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-mainDark p-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 text-white">
          <div className="flex items-center justify-between">
            <Logo />
            <button
              type="button"
              className="-m-2.5 p-2.5 text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    to={item.href}
                    className="-mx-3 block  px-3 py-2 text-base font-semibold leading-7  hover:bg-main"
                    key={item.name}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>

              <div className="py-3">
                <Link
                  to="/contact"
                  className=" -mx-3 block  px-3 py-2 text-base font-semibold leading-7  hover:bg-main  hover:text-white"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Contact Us
                </Link>

                {/* <a
                  href="#"
                  className="-mx-3 block  px-3 py-2.5 text-base font-semibold leading-7  hover:bg-main"
                >
                  Log in
                </a> */}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
