import React from "react";

const Badge = ({ label }) => {
  return (
    <span className=" rounded-full bg-gray-300 px-2.5 py-1 text-xs font-medium text-gray-800 ml-3">
      {label}
    </span>
  );
};

export default Badge;
