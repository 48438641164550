import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div className="flex lg:flex-1">
      <Link to="/" className="-m-1.5 p-1.5">
        <span className="sr-only">CoinAdoption</span>
        <span className="text-xl">
          Coin<span className="text-main">Adoption</span>
        </span>
      </Link>
    </div>
  );
};

export default Logo;
